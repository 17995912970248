<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()
</script>

<template>
  <div id="collections-layout">
    <Html>
      <Body class="text-gray-700 font-body bg-gray-50 antialiased">
        <nav data-x-section="nav" class="flex items-center justify-between">
          <NuxtLink :to="localePath('index')" data-x-name="home" class="px-8 py-5 bg-violet-900">
            <img
              src="/logo/full-white.svg"
              width="162"
              height="32"
              alt="AppGuide"
              class="w-[120px] h-[24px] md:w-[162px] md:h-[32px]"
            >
          </NuxtLink>

          <UtilsLangSwitcher class="mr-2 sm:mr-8" />
        </nav>
        <div>
          <slot />
        </div>

        <footer data-x-section="footer" class="max-w-4xl mx-auto mt-28 md:mt-36">
          <div class="flex flex-col items-center py-12 mx-4 text-center border-t-2 border-gray-300">
            <NuxtLink :to="localePath('index')" class="mb-6">
              <img
                src="/logo/full.svg"
                width="162"
                height="32"
                alt="AppGuide"
                class="w-[120px] h-[24px]"
              >
            </NuxtLink>

            <div class="space-y-4 text-xs">
              <p class="">
                {{ t('powered-by') }}
                <!-- <a href="https://therappx.com" rel="noopener noreferrer" target="_blank">TherAppX</a> --><span>TherAppX</span>,
                &copy; {{ new Date().getFullYear() }} TherAppX
              </p>
              <p>
                {{ t('for-technical-problem[0]') }}<br>
                {{ t('for-technical-problem[1]') }}
                <a href="mailto:support@therappx.com" class="font-bold transition-colors hover:text-blue-500">support@therappx.com</a>
              </p>
              <ul class="flex flex-wrap items-center justify-center gap-y-2 gap-x-4">
                <li>
                  <NuxtLink :to="localePath('terms-and-conditions')" class="font-bold underline transition-colors hover:text-blue-500">
                    {{ t('terms-and-conditions') }}
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="localePath('privacy-policy')" class="font-bold underline transition-colors hover:text-blue-500">
                    {{ t('privacy-policy') }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </Body>
    </Html>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "powered-by": "AppGuide is powered by",
    "for-technical-problem": [
      "For any technical problem, confidentiality issue or to report any health problem related to the use",
      " of AppGuide, please contact us at "
    ],
    "terms-and-conditions": "Terms and conditions",
    "privacy-policy": "Privacy policy"
  },
  "fr": {
    "powered-by": "AppGuide est propulsé par",
    "for-technical-problem": [
      "Pour tout problème technique, question de confidentialité ou pour signaler tout problème de santé lié",
      " à l'utilisation d'AppGuide, merci de nous contacter à "
    ],
    "terms-and-conditions": "Termes et conditions",
    "privacy-policy": "Politique de confidentialité"
  }
}
</i18n>
